import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header.jsx';
import About from './components/About.jsx';
import Services from './components/Services.jsx';
import Reviews from './components/Reviews.jsx';
import Contact from './components/Contact.jsx';
import Gallery from './components/pages/Gallery.jsx';
import Admin from './components/pages/Admin.jsx';
import Maintenance from './components/pages/Maintenance.jsx';
import { useEffect, useState } from 'react';

import { db, getDoc, doc, collection } from './components/pages/firebase.js';


const App = () => {

  const docRef = doc(db, "maintenance", "SF");


  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  useEffect(() => {
    const fetchMaintenanceMode = async () => {
      const docRef = doc(db, "maintenance", "system");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setIsMaintenanceMode(docSnap.data().isMaintenanceMode);
      } else {
        console.log("No such document!");
      }
    };

    fetchMaintenanceMode();
  }, []);
  const docSnap = getDoc(docRef);


  return (
    <Router>
      <AppRoutes isMaintenanceMode={isMaintenanceMode} />
    </Router>
  );
};

const AppRoutes = ({ isMaintenanceMode }) => {
  const location = useLocation();

  if (isMaintenanceMode && location.pathname !== '/admin') {
    return <Maintenance />;
  }

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <About />
            <Services />
            <Reviews />
            <Contact />
          </>
        } />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </>
  );
};

export default App;
