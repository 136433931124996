import React from 'react';

const Reviews = () => {
  return (
    <section id="reviews">
      <h2>Customer Reviews</h2>
      <blockquote>
        <cite>"Done an amazing job putting vinyl in my kitchen, wouldn’t ask anyone else!!! Thank you"</cite>
      </blockquote>
      <br />
      <blockquote>
        <cite>"10 out of 10 for the fitting of my carpet and vinyl flooring. Great prices. Will definitely use again and recommend to others 👌"</cite>
      </blockquote>
      <br />
      <blockquote>
        <cite>"Fantastic service very good pricing alot cheaper than others in the area for the same carpet fitting was done quickly all rubbish removed couldn't be happier highly recommended."</cite>
      </blockquote>
    </section>
  );
};

export default Reviews;
