import React from 'react';

const About = () => {
  return (
    <section id="about">
      <span>
        <h2
        style={{
          padding: '10px',
          fontfamily: 'Inter',
          fontweight: 'black',
          fontsize: '2rem',
        }}>About The Carpet Guys Robert Fines</h2>
        <p>Wisbech Based Professional Carpet & Vinyl Flooring Suppliers & Fitters Covering The Floors of Fenland</p>
      </span>
    </section>
  );
};

export default About;
