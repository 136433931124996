import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  var logo = "https://thecarpetguysrobertfines.co.uk/assets/logo.png"

  return (
    <header>
      <nav>
        <div className="logo">
          <img src={logo} alt="Logo" />
          <h2>&nbsp;&nbsp;The Carpet Guys Robert Fines</h2>
        </div>

        <div className="hamburger" onClick={toggleMenu}>
          &#9776;
        </div>

        <ul className={isOpen ? "nav-links active" : "nav-links"}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/gallery">Gallery</Link></li>
        </ul>

        <ul className="desktop-nav">
          <span>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/gallery">Gallery</Link></li>
          </span>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
