import React from 'react';
import GenerateImgs from '../GenerateImgs.jsx';

const Gallery = () => {
  const galleryStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto',
    padding: '1rem',
  };

  const headingStyle = {
    marginTop: '1rem',
    padding: '10px',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    fontSize: '2rem',
    textAlign: 'center',
    display: 'inline-block',
    borderRadius: '10px',
  };

  return (
    <div style={galleryStyle}>
      <h2 style={headingStyle}>Our Carpet Collection</h2>
      <GenerateImgs />
    </div>
  );
};

export default Gallery;
