import React from 'react';

const Services = () => {
  return (
    <section id="services">
      <h2>Our Services</h2>
      <ul>
        <li>Free Local Measuring & Sample Service</li>
        <li>Quality Flooring @ Affordable Prices</li>
        <li>Professional Fitting</li>
      </ul>
    </section>
  );
};

export default Services;
