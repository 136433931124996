import React from 'react';

const Contact = () => {
  return (
    <section id="contact">
      <h2>Contact Us</h2>
      <p className='contactpara'>
        <span className="material-icons">phone</span> 
        <a href="tel:07809 831333">07809 831333</a>
      </p>
      <p className='contactpara'>
        <span className="material-icons">email</span> 
        <a href="mailto:thecarpetguysfenland@gmail.com">thecarpetguysfenland@gmail.com</a>
      </p>
      <p className='contactpara'>
        <span className="material-icons">facebook</span> 
        Follow Us:&nbsp;&nbsp;<a href="https://www.facebook.com/RobertFinesCarpetFitting/">Click me</a>
      </p>
    </section>
  );
};

export default Contact;
