import React, { useEffect, useState } from "react";

const GenerateImgs = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch("https://www.thecarpetguysrobertfines.co.uk/carpets.json");
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                
                const formattedImages = data.map((item) => ({
                    src: `https://www.thecarpetguysrobertfines.co.uk/assets/carpets/${item.image}`,
                    text: item.name,
                }));

                setImages(formattedImages);
            } catch (error) {
                console.error("Failed to fetch images:", error);
            }
        };

        fetchImages();
    }, []);

    return (
        <div className="image-gallery-container">
            {images.map((image, index) => (
                <div key={index} className="image-container">
                    <img src={image.src} alt={`image-${index}`} />
                    <p><b>{image.text}</b></p>
                </div>
            ))}
        </div>
    );
};

export default GenerateImgs;
