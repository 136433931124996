import React from 'react';

const Maintenance = () => {
  const styles = {
    container: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#f4f4f4',
      color: '#333',
    },
    content: {
      textAlign: 'center',
      background: 'white',
      padding: '40px',
      borderRadius: '12px',
      boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)',
      maxWidth: '600px',
    },
    h1: {
      fontSize: '2.8rem',
      color: '#2c3e50',
      marginBottom: '20px',
    },
    p: {
      fontSize: '1.2rem',
      color: '#555',
      marginBottom: '30px',
    },
    logo: {
      maxWidth: '120px',
      marginBottom: '20px',
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <img 
          src="https://www.thecarpetguysrobertfines.co.uk/assets/logo.png" 
          alt="The Carpet Guys Logo" 
          style={styles.logo} 
        />
        <h1 style={styles.h1}>We'll Be Back Soon!</h1>
        <p style={styles.p}>
          Our website is currently undergoing scheduled maintenance. We’ll be back online shortly! Thank you for your patience.
        </p>
      </div>
    </div>
  );
};

export default Maintenance;
