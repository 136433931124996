import React, { useState, useEffect } from 'react';
import { auth, signInWithEmailAndPassword, db, doc, getDoc, setDoc } from "./firebase.js";

const Admin = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);


  const handleLogout = () => {
    auth.signOut().then(() => {
      setLoggedIn(false);
    });
  }


  useEffect(() => {
    const fetchMaintenanceMode = async () => {
      const docRef = doc(db, 'settings', 'maintenance');
      const docSnap = await getDoc(docRef);

      auth.onAuthStateChanged((user) => {
        if (user) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      });

      if (docSnap.exists()) {
        setIsMaintenanceMode(docSnap.data().isMaintenanceMode);
      }
    };
    fetchMaintenanceMode();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, username, password);
      setLoggedIn(true);
      alert('Successfully logged in!');
    } catch (error) {
      alert('Invalid login credentials!');
    }
  };

  const toggleMaintenanceMode = async () => {
    const newMaintenanceMode = !isMaintenanceMode;
    setIsMaintenanceMode(newMaintenanceMode);
    try {
      await setDoc(doc(db, 'maintenance', 'system'), { isMaintenanceMode: newMaintenanceMode });
    } catch (error) {
      alert('Failed to update maintenance mode. Please try again.');
      newMaintenanceMode = !newMaintenanceMode;
      setIsMaintenanceMode(newMaintenanceMode);
    }
  };



  const styles = {
    loginContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#f0f0f0' },
    loginForm: { backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' },
    h2: { marginBottom: '20px', fontSize: '24px', color: '#333' },
    input: { width: '100%', padding: '10px', marginBottom: '15px', border: '1px solid #ddd', borderRadius: '4px', fontSize: '16px' },
    loginButton: { width: '100%', padding: '10px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '4px', fontSize: '16px', cursor: 'pointer', transition: 'background-color 0.3s ease' },
    dashboard: { padding: '40px', backgroundColor: '#fff', maxWidth: '800px', margin: '100px auto', borderRadius: '12px', boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)' },
    actions: { display: 'flex', justifyContent: 'space-around', marginTop: '20px' },
    button: { padding: '15px 30px', backgroundColor: isMaintenanceMode ? '#dc3545' : '#28a745', color: 'white', border: 'none', borderRadius: '6px', fontSize: '16px', cursor: 'pointer', transition: 'background-color 0.3s ease' },
  };

  if (!loggedIn) {
    return (
      <div style={styles.loginContainer}>
        <h2 style={styles.h2}>Admin Login</h2>
        <form onSubmit={handleLogin} id="loginPage" style={styles.loginForm}>
          <input
            type="email"
            placeholder="Email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={styles.input}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
          />
          <button type="submit" style={styles.loginButton}>
            Login
          </button>
        </form>
      </div>
    );
  }

  return (
    <div style={styles.dashboard}>
      <h2 style={styles.h2}>Admin Dashboard</h2>
      <p>Welcome to the admin panel! From here, you can manage the website.</p>
      <div style={styles.actions}>
        <button style={styles.button} onClick={toggleMaintenanceMode}>
          {isMaintenanceMode ? 'Disable Maintenance Mode' : 'Enable Maintenance Mode'}
        </button>
        <button style={styles.button}>View Site Stats</button>
        <button style={styles.button}>Manage Content</button>
      </div>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Admin;
